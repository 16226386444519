

































import { Component, Prop } from 'vue-property-decorator';
import ConservatorshipTypeChangeDialog from './ConservatorshipTypeChangeDialog.vue';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';
import CourtSelect from '@/modules/court/components/CourtSelect.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import { WriteConservatorship } from '@/modules/conservatorship/types';
import { AuthorizationMixin } from '@/views/mixin';

@Component({
  components: {
    ConservatorshipTypeChangeDialog,
    DatePicker,
    CourtSelect,
    ConservatorSelect
  }
})
export default class CreateNewConservatorshipCard extends AuthorizationMixin {
  @Prop({ type: Object, required: true }) value!: WriteConservatorship;

  get conservatorship(): WriteConservatorship {
    return this.value;
  }

  set conservatorship(value: WriteConservatorship) {
    this.$emit('input', value);
  }

  created() {
    if (!this.isConservator()) return;

    this.conservatorship.conservator_id = this.$auth.user().id;
  }
}
