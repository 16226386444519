



















import { Component, Prop, Vue } from 'vue-property-decorator';
import ConservatorshipTypeSelect from './ConservatorshipTypeSelect.vue';

@Component({
  components: { ConservatorshipTypeSelect }
})
export default class ConservatorshipTypeCard extends Vue {
  @Prop({ type: String }) value?: string;

  get type(): string | undefined {
    return this.value;
  }

  set type(value: string | undefined) {
    this.$emit('input', value);
  }
}
