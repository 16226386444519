
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, GET_LIST } from '../store';
import { Court } from '../types';
import { ApiResponse } from '@/components/types';

const Court = namespace('court');

@Component({ inheritAttrs: false })
export default class CourtSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  @Court.Getter(GET_LIST) list!: Court[];
  @Court.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;

  get items() {
    return this.list.sort((first, second) => first.name.localeCompare(second.name));
  }

  created() {
    if (this.list.length !== 0) return;

    return this.fetch();
  }
}
