













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SalutationSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  list: Array<{ value: string, text: string }> = [
    { value: 'Herr', text: 'Herr' },
    { value: 'Frau', text: 'Frau' }
  ];

  created() {
    this.$emit('input', this.value);
  }
}
