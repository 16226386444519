













import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({
  inheritAttrs: false
})
export default class ListenableSelect extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  get list(): Array<{ value: boolean, text: TranslateResult }> {
    return [
      { value: false, text: this.$i18n.t('common.no') },
      { value: true, text: this.$i18n.t('common.yes') }
    ];
  }

  created() {
    this.$emit('input', this.value);
  }
}
