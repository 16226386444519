

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, GET_LIST } from '../store';
import { ScopeOfFunctions } from '../types';
import { ApiResponse } from '@/components/types';

const ScopesOfFunctions = namespace('scopeOfFunctions');

@Component({})
export default class ScopesOfFunctionsCheckboxGroup extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: string[];

  @ScopesOfFunctions.Getter(GET_LIST) list!: ScopeOfFunctions[];
  @ScopesOfFunctions.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;

  get items(): ScopeOfFunctions[] {
    return this.list.sort((first, second) => first.name.localeCompare(second.name));
  }

  get selected(): string[] {
    return this.value;
  }

  set selected(value: string[]) {
    this.$emit('input', value);
  }

  created() {
    if (this.list.length !== 0) return;

    return this.fetch();
  }
}
