































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ConservatorshipTypeSelect from './ConservatorshipTypeSelect.vue';

@Component({ components: { ConservatorshipTypeSelect } })
export default class ConcernedCourtChangeDialog extends Vue {
  @Prop({ type: String, required: true }) value!: string;

  dialog: boolean = false;
  newType: string = this.value;
  error: any = null;

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog === false) {
      // @ts-ignore
      this.$refs.form.reset();
      return;
    }

    this.newType = this.value;
  }

  async save() {
    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.$emit('input', this.newType);

    this.dialog = false;
  }
}
