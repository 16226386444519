









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class FormActions extends Vue {
  @Prop({ type: Boolean, default: false }) saving!: boolean;
  @Prop({ type: Function, required: false }) save?: (...args: any) => any;
  @Prop({ type: Function, required: false }) back?: (...args: any) => any;
}
