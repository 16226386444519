import { Address, WriteAddress, WriteConservatorship } from '@/modules/conservatorship/types';
import uuid from 'uuid/v4';
import format from '@/filter/dateFormat';

export const createConservatorship = (): WriteConservatorship => ({
  conservatorship_id: uuid(),
  type: null,
  court_id: '',
  attorney_docket: '',
  assigned: format(Date.now(), 'yyyy-MM-dd'),
  begin: format(Date.now(), 'yyyy-MM-dd'),
  conservator_id: '',
  scopes_of_functions: [],
  conservatee: {
    forename: '',
    surname: '',
    salutation: '',
    birthday: format(Date.now(), 'yyyy-MM-dd'),
    financial_state: null,
    placement: null,
    listenable: false,
    address: {
      name: '',
      additional: '',
      street: '',
      zip_code: '',
      city: ''
    }
  }
});

export const MapAddressToWriteAddress = ({ zipCode, ...address }: Address): WriteAddress => ({
  ...address,
  zip_code: zipCode
});
