












import { Component, Prop, Vue } from 'vue-property-decorator';
import { Placement } from '@/modules/conservatorship/types';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class PlacementSelect extends Vue {
  @Prop({ type: String }) value?: Placement;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  get list(): Array<{ value: Placement, text: TranslateResult }> {
    return [
      { value: 'home', text: this.$i18n.t('conservatorship.placement.home') },
      { value: 'asylum', text: this.$i18n.t('conservatorship.placement.asylum') }
    ];
  }
}
