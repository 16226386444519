









































import { Component, Prop, Vue } from 'vue-property-decorator';
import ConservatorshipTypeChangeDialog from './ConservatorshipTypeChangeDialog.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import ListenableSelect from '../ListenableSelect.vue';
import PlacementSelect from '../PlacementSelect.vue';
import FinancialStateSelect from '../FinancialStateSelect.vue';
import SalutationSelect from '../SalutationSelect.vue';
import { WriteConservatee } from '@/modules/conservatorship/types';

@Component({
  components: {
    SalutationSelect,
    ConservatorshipTypeChangeDialog,
    DatePicker,
    ListenableSelect,
    FinancialStateSelect,
    PlacementSelect
  }
})
export default class CreateNewConservatorshipCard extends Vue {
  @Prop({ type: Object, required: true }) value!: WriteConservatee;

  get conservatee(): WriteConservatee {
    return this.value;
  }

  set conservatee(value: WriteConservatee) {
    this.$emit('input', value);
  }
}
