

























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SET_CREATED } from '../store/mutations';
import { GET_CREATED } from '../store/getters';
import { SAVE_CONSERVATORSHIP } from '../store/actions';
import AddressCard from '../components/create/AddressCard.vue';
import { WriteConservatorship } from '@/modules/conservatorship/types';
import { ApiResponse } from '@/components/types';
import { createConservatorship } from '@/modules/conservatorship/model';
import ConservatorshipTypeCard from '../components/create/ConservatorshipTypeCard.vue';
import CreateNewConservatorshipCard from '../components/create/CreateNewConservatorshipCard.vue';
import AssumeExternalConservatorshipCard from '../components/create/AssumeExternalConservatorshipCard.vue';
import ScopesOfFunctionsCard from '../components/create/ScopesOfFunctionsCard.vue';
import ConservateeCard from '../components/create/ConservateeCard.vue';
import FormActions from '@/components/form/FormActions.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    AddressCard,
    ConservatorshipTypeCard,
    CreateNewConservatorshipCard,
    AssumeExternalConservatorshipCard,
    ScopesOfFunctionsCard,
    ConservateeCard,
    FormActions
  }
})
export default class Create extends Vue {
  @Conservatorship.Mutation(SET_CREATED) setCreated!: (conservatorship: WriteConservatorship | null) => void;
  @Conservatorship.Getter(GET_CREATED) getCreated!: WriteConservatorship | null;
  @Conservatorship.Action(SAVE_CONSERVATORSHIP) saveConservatorship!: (conservatorship: WriteConservatorship) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  get conservatorship(): WriteConservatorship | null {
    return this.getCreated;
  }

  set conservatorship(value: WriteConservatorship | null) {
    this.setCreated(value);
  }

  get conservatorshipCard(): string {
    if (!this.conservatorship) return 'span';

    if (this.conservatorship.type === 'new') {
      return 'create-new-conservatorship-card';
    }

    return 'assume-external-conservatorship-card';
  }

  created() {
    this.conservatorship = {
      ...createConservatorship(),
      ...(this.conservatorship || {})
    };
  }

  async save() {
    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result || !this.conservatorship) return;

    this.error = null;
    this.loading = true;

    const { error } = await this.saveConservatorship(this.conservatorship);

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    setTimeout(() => {
      this.conservatorship = null;
    }, 100);

    this.$router.push({
      name: 'conservatorship-details',
      params: { id: this.conservatorship.conservatorship_id }
    }).catch(() => {
    });
  }

  cancel() {
    this.conservatorship = null;

    this.$router.push({ name: 'conservatorship-list' }).catch(() => {
    });
  }
}
