




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { WriteAddress } from '@/modules/conservatorship/types';

@Component({})
export default class AddressCard extends Vue {
  @Prop({ type: Object, required: true }) value!: WriteAddress;

  get address(): WriteAddress {
    return this.value;
  }

  set address(value: WriteAddress) {
    this.$emit('input', value);
  }
}
