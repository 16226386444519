






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { WriteConservatorship } from '@/modules/conservatorship/types';
import ScopesOfFunctionsCheckboxGroup from '@/modules/scopeOfFunctions/components/ScopesOfFunctionsCheckboxGroup.vue';

@Component({
  components: {
    ScopesOfFunctionsCheckboxGroup
  }
})
export default class CreateNewConservatorshipCard extends Vue {
  @Prop({ type: Object, required: true }) value!: WriteConservatorship;

  get conservatorship(): WriteConservatorship {
    return this.value;
  }

  set conservatorship(value: WriteConservatorship) {
    this.$emit('input', value);
  }
}
