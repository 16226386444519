












import { Component, Prop, Vue } from 'vue-property-decorator';
import { Type } from '@/modules/conservatorship/types';

@Component({})
export default class ConservatorshipTypeSelect extends Vue {
  @Prop({ type: String }) value?: Type;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  list: Array<{ value: Type, text: string }> = [
    { value: 'assigned', text: 'Betreuungsübernahme' },
    { value: 'new', text: 'Neue Betreuung' }
  ];
}
