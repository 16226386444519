












import { Component, Prop, Vue } from 'vue-property-decorator';
import { FinancialState } from '@/modules/conservatorship/types';

@Component({})
export default class FinancialStateSelect extends Vue {
  @Prop({ type: String }) value?: FinancialState;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  list: Array<{ value: FinancialState, text: string }> = [
    { value: 'wealthy', text: 'vermögend' },
    { value: 'destitute', text: 'mittellos' }
  ];
}
